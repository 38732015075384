import React from "react";
import "./sections.css";
import ImageIcon from "../components/imageIcon";

const MembershipCategories = (props) => {
  return (
    <div>
      <div
        data-aos="fade-down-left"
        data-aos-delay="100"
        data-aos-duration="4000"
        className="col-12 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-10"
      >
        <h2 className="text-900 font-normal mb-2">{props.title}</h2>
        {/* <span className="text-600 text-2xl">Placerat in egestas erat...</span> */}
      </div>
      <div
        data-aos="fade-right"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="col-12 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0"
      >
        <div
          style={{
            height: "fit-content",
            padding: "2px",
            // borderRadius: "10px",
            borderTopLeftRadius: "50px",
            borderBottomRightRadius: "50px",
            backgroundColor: "white",
            // "linear-gradient(90deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2))",
          }}
        >
          <div className="p-3 h-full" style={{ borderRadius: "8px" }}>
            {/* <div
                                        className="flex align-items-center justify-content-center bg-yellow-200 mb-3"
                                        style={{
                                            width: '3.5rem',
                                            height: '3.5rem',
                                            borderRadius: '10px'
                                        }}
                                    >
                                        <i className="pi pi-fw pi-users text-2xl text-yellow-700"></i>
                                    </div> */}
            <h5
              className="mb-2 text-green-800"
              style={{ display: "flex", alignItems: "center" }}
            >
              <ImageIcon
                imageUrl="/icciu_pics/member.png"
                altText="Icon"
                height1="70px"
                width1="70px"
              />
              Small medium enterprises (SMEs)
            </h5>
            <span className="text-600"></span>
          </div>
        </div>
      </div>

      <div
        data-aos="fade-right"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="col-12 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0"
      >
        <div
          style={{
            height: "fit-content",
            padding: "2px",
            borderTopLeftRadius: "50px",
            borderBottomRightRadius: "50px",
            backgroundColor: "white",
            // "linear-gradient(90deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2))",
          }}
        >
          <div className="p-3 h-full" style={{ borderRadius: "8px" }}>
            {/* <div
                                        className="flex align-items-center justify-content-center bg-yellow-200 mb-3"
                                        style={{
                                            width: '3.5rem',
                                            height: '3.5rem',
                                            borderRadius: '10px'
                                        }}
                                    >
                                        <i className="pi pi-fw pi-users text-2xl text-yellow-700"></i>
                                    </div> */}
            <h5
              className="mb-2 text-green-800"
              style={{ display: "flex", alignItems: "center" }}
            >
              <ImageIcon
                imageUrl="/icciu_pics/member.png"
                altText="Icon"
                height1="70px"
                width1="70px"
              />
              Corporate Companies
            </h5>
            <span className="text-600"></span>
          </div>
        </div>
      </div>
      <div
        data-aos="fade-right"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="col-12 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0"
      >
        <div
          style={{
            height: "fit-content",
            padding: "2px",
            borderTopLeftRadius: "50px",
            borderBottomRightRadius: "50px",
            backgroundColor: "white",
            // "linear-gradient(90deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2))",
          }}
        >
          <div className="p-3 h-full" style={{ borderRadius: "8px" }}>
            {/* <div
                                        className="flex align-items-center justify-content-center bg-yellow-200 mb-3"
                                        style={{
                                            width: '3.5rem',
                                            height: '3.5rem',
                                            borderRadius: '10px'
                                        }}
                                    >
                                        <i className="pi pi-fw pi-users text-2xl text-yellow-700"></i>
                                    </div> */}
            <h5
              className="mb-2 text-green-800"
              style={{ display: "flex", alignItems: "center" }}
            >
              <ImageIcon
                imageUrl="/icciu_pics/member.png"
                altText="Icon"
                height1="70px"
                width1="70px"
              />
              Manufactures
            </h5>
            <span className="text-600"></span>
          </div>
        </div>
      </div>
      <div
        data-aos="fade-right"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="col-12 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0"
      >
        <div
          style={{
            height: "fit-content",
            padding: "2px",
            borderTopLeftRadius: "50px",
            borderBottomRightRadius: "50px",
            backgroundColor: "white",
            // "linear-gradient(90deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2))",
          }}
        >
          <div className="p-3 h-full" style={{ borderRadius: "8px" }}>
            {/* <div
                                        className="flex align-items-center justify-content-center bg-yellow-200 mb-3"
                                        style={{
                                            width: '3.5rem',
                                            height: '3.5rem',
                                            borderRadius: '10px'
                                        }}
                                    >
                                        <i className="pi pi-fw pi-users text-2xl text-yellow-700"></i>
                                    </div> */}
            <h5
              className="mb-2 text-green-800"
              style={{ display: "flex", alignItems: "center" }}
            >
              <ImageIcon
                imageUrl="/icciu_pics/member.png"
                altText="Icon"
                height1="70px"
                width1="70px"
              />
              Civil society Organizations (CSOs)
            </h5>
            <span className="text-600"></span>
          </div>
        </div>
      </div>
      <div
        data-aos="fade-right"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="col-12 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0"
      >
        <div
          style={{
            height: "fit-content",
            padding: "2px",
            borderTopLeftRadius: "50px",
            borderBottomRightRadius: "50px",
            backgroundColor: "white",
            // "linear-gradient(90deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2))",
          }}
        >
          <div className="p-3 h-full" style={{ borderRadius: "8px" }}>
            {/* <div
                                        className="flex align-items-center justify-content-center bg-yellow-200 mb-3"
                                        style={{
                                            width: '3.5rem',
                                            height: '3.5rem',
                                            borderRadius: '10px'
                                        }}
                                    >
                                        <i className="pi pi-fw pi-users text-2xl text-yellow-700"></i>
                                    </div> */}
            <h5
              className="mb-2 text-green-800"
              style={{ display: "flex", alignItems: "center" }}
            >
              <ImageIcon
                imageUrl="/icciu_pics/member.png"
                altText="Icon"
                height1="70px"
                width1="70px"
              />
              Foreign Direct Investments (FDIs)
            </h5>
            <span className="text-600"></span>
          </div>
        </div>
      </div>
      <div
        data-aos="fade-right"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="col-12 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0"
      >
        <div
          style={{
            height: "fit-content",
            padding: "2px",
            borderTopLeftRadius: "50px",
            borderBottomRightRadius: "50px",
            backgroundColor: "white",
            // "linear-gradient(90deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2))",
          }}
        >
          <div className="p-3 h-full" style={{ borderRadius: "8px" }}>
            {/* <div
                                        className="flex align-items-center justify-content-center bg-yellow-200 mb-3"
                                        style={{
                                            width: '3.5rem',
                                            height: '3.5rem',
                                            borderRadius: '10px'
                                        }}
                                    >
                                        <i className="pi pi-fw pi-users text-2xl text-yellow-700"></i>
                                    </div> */}
            <h5
              className="mb-2 text-green-800"
              style={{ display: "flex", alignItems: "center" }}
            >
              <ImageIcon
                imageUrl="/icciu_pics/member.png"
                altText="Icon"
                height1="70px"
                width1="70px"
              />
              Ministries, Departments and Agencies (MDAs)
            </h5>
            <span className="text-600"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipCategories;
