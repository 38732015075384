import "../App.css";
import React from "react";
// import Link from "react-router-dom";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primeicons/primeicons.css";
import "/node_modules/primeflex/primeflex.css";
import StrategicStatements from "../sections/strategicStatements";
import MasterPage from "../masterPage";

function Statements() {
  return (
    <MasterPage
      imageUrl="/icciu_pics/slide4.jpeg"
      caption="The Islamic Chamber of Commerce and Industry Uganda - ICCIU"
      blackBackground={true}
      currentLink="/about/statements"
      article={1}
    >
      <StrategicStatements title="Strategic Statements" />
    </MasterPage>
  );
}

export default Statements;
