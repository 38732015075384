import React, { useEffect } from "react";
import "./components.css"; // Import CSS file for styling
import anime from "animejs/lib/anime.es.js";

const ImageWithCaption = ({ imageUrl, caption, blackBackground }) => {
  const containerStyle =
    window.innerWidth > 768
      ? {
          position: "relative",
          display: "inline-block",
          marginRight: "5%",
          marginLeft: "10%",
          marginTop: "10%",
          width: "80%",
        }
      : {
          position: "relative",
          display: "inline-block",
          marginRight: "5%",
          marginTop: "10%",
          width: "100%",
        };

  const imageStyle = {
    // maxWidth: "100%",
    // maxHeight: "100%",
    width: "100%",
    display: "block",
  };

  useEffect(() => {
    const textWrapper = document.querySelector(".ml3");
    textWrapper.innerHTML = textWrapper.textContent.replace(
      /\S/g,
      "<span class='letter'>$&</span>"
    );

    anime
      .timeline({ loop: true })
      .add({
        targets: ".ml3 .letter",
        opacity: [0, 1],
        easing: "easeInOutQuad",
        duration: 2250,
        delay: (el, i) => 150 * (i + 1),
      })
      .add({
        targets: ".ml3",
        opacity: 0,
        duration: 1000,
        easing: "easeOutExpo",
        delay: 5000,
      });

    // Cleanup function
    return () => {
      anime.remove(textWrapper);
    };
  }, []);

  const overlayStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: blackBackground ? "white" : "black",
    backgroundColor: blackBackground
      ? "rgba(0, 0, 0, 0.6)"
      : "rgba(255, 255, 255, 0.6)",
    padding: "10px",
    borderRadius: "20px",

    width: "100%",
  };

  return (
    <div
      data-aos="zoom-in"
      data-aos-delay="50"
      data-aos-duration="1000"
      style={containerStyle}
    >
      <img src={imageUrl} alt="Image" style={imageStyle} />
      <div style={overlayStyle}>
        <p
          className="ml3 align-content-center text-center"
          style={{ fontSize: "2vw" }}
        >
          {caption}
        </p>
      </div>
    </div>
  );
};

export default ImageWithCaption;
