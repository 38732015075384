import React from "react";
import "./App.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primeicons/primeicons.css";
import "/node_modules/primeflex/primeflex.css";
import NavBar from "./components/navBar";
import Footer from "./sections/footer";
import MarqueeSlider from "./sections/Partnerships";
import ImageWithCaption from "./components/ImageDiv";
import Updates from "./sections/updates";
import StackedLinks from "./sections/links";
import RelatedArticles from "./sections/RelatedArticles";

const MasterPage = ({
  children,
  imageUrl,
  caption,
  blackBackground,
  currentLink = "",
  article = 1,
}) => {
  const links = [
    ["/about", "About Us"],
    ["/about/statements", "Strategic Statements"],
    ["/about/mandate", "Mandate"],
    ["/about/objectives", "Strategic Objectives"],
    ["/about/activities", "Key Activities"],
    ["/programs", "Programs"],
    ["/programs/ramadhan2024", "Ramadhan 2024"],
    ["/programs/membership", "Membership"],
    ["/programs/trade", "Trade and Commerce"],
    ["/programs/publications", "Publications"],
    ["/programs/zakat", "Zakat and Charity"],
    // ["/programs", "Programs"],
    // ["/about", "About Us"],
    // ["/contact", "Contact Us"],
  ];

  const main = [
    "/icciu_pics/mainPhotos/1.jpeg",
    "/icciu_pics/mainPhotos/2.png",
    "/icciu_pics/mainPhotos/3.jpeg",
    "/icciu_pics/mainPhotos/4.jpeg",
    "/icciu_pics/mainPhotos/5.jpg",
    "/icciu_pics/mainPhotos/6.jpeg",
    "/icciu_pics/mainPhotos/7.jpeg",
    "/icciu_pics/mainPhotos/8.jpeg",
    "/icciu_pics/mainPhotos/9.jpeg",
    "/icciu_pics/mainPhotos/10.jpeg",
  ];

  const f4 = [
    {
      image: "/icciu_pics/1feasibilitystudy/2.jpeg",
      title:
        "ICCIU's March 2024 Effort: Strengthening Assistance for Sudanese Refugees in Kiryandongo Settlement",
      date: "March 6, 2024",
    },
    {
      image: "/icciu_pics/1feasibilitystudy/3.jpeg",
      title:
        "Empowering Sudanese Refugees: ICCIU Feasibility Study in Kiryandongo Settlement",
      date: "March 6, 2024",
    },
    {
      image: "/icciu_pics/1feasibilitystudy/4.jpeg",
      title:
        "ICCIU Assesses Feasibility to Aid Sudanese Refugees in Kiryandongo",
      date: "March 6, 2024",
    },
    {
      image: "/icciu_pics/1feasibilitystudy/5.jpeg",
      title:
        "ICCIU Feasibility Study: Enhancing Support for Sudanese Refugees in Kiryandongo Settlement",
      date: "March 6, 2024",
    },
  ];

  const t1 = [
    {
      image: "/icciu_pics/2Turkish/1.jpeg",
      title:
        "Turkish Uganda Humanitarian Foundation Donates Two Cows for IDD Feast Celebration",
      date: "Idd Al Fitr, 2024",
    },
  ];

  const m2 = [
    {
      image: "/icciu_pics/3refugeeMothers/1.jpeg",
      title:
        "Urgent Appeal: Support Needed for 20 Expecting Refugee Mothers in Kiryandongo Settlement",
      date: "2024",
    },
    {
      image: "/icciu_pics/3refugeeMothers/2.jpeg",
      title:
        "Help Provide Delivery Packages and Essentials for Pregnant Refugee Mothers in Kiryandongo - $100 Needed Per Mother",
      date: "2024",
    },
  ];

  const r6 = [
    {
      image: "/icciu_pics/4supportRefugee/1.jpeg",
      title: "Support Needed for Sudanese Refugees in Kiryandongo Settlement",
      date: "2024",
    },
    {
      image: "/icciu_pics/4supportRefugee/9.jpeg",
      title:
        "Your Contribution Can Help Refugee Families in Kiryandongo Thrive",
      date: "2024",
    },
    {
      image: "/icciu_pics/4supportRefugee/8.jpeg",
      title:
        "Stand with Kiryandongo's Refugees: Donate Today for Immediate Relief",
      date: "2024",
    },
    {
      image: "/icciu_pics/4supportRefugee/4.jpeg",
      title:
        "Support the Health and Well-being of Refugees in Kiryandongo Settlement",
      date: "2024",
    },
    {
      image: "/icciu_pics/4supportRefugee/5.jpeg",
      title:
        "Be a Lifeline for Refugees in Kiryandongo: Essential Donations Needed",
      date: "2024",
    },
    {
      image: "/icciu_pics/4supportRefugee/6.jpeg",
      title:
        "Empower Refugee Families in Kiryandongo with Your Generous Support",
      date: "2024",
    },
  ];

  const v8 = [
    {
      image: "/icciu_pics/5vicepresident/1.jpeg",
      title:
        "Mr. Arinda Razak: Vice President Meeting - Discussing Refugee Support Initiatives",
      date: "2024",
    },
    {
      image: "/icciu_pics/5vicepresident/3.jpeg",
      title:
        "Ramadan Donation: 15,000 Boxes for Kiryandongo Refugees and Host Community",
      date: "2024",
    },
    {
      image: "/icciu_pics/5vicepresident/14.jpeg",
      title: "Cooperative Endeavors: Extending a Helping Hand to Refugees",
      date: "2024",
    },
    {
      image: "/icciu_pics/5vicepresident/13.jpeg",
      title: "Coordinated Efforts: Offering Relief to Displaced Persons",
      date: "2024",
    },
    {
      image: "/icciu_pics/5vicepresident/9.jpeg",
      title: "Direct Aid Society Enhances Refugee Support in Adjumani District",
      date: "2024",
    },
    {
      image: "/icciu_pics/5vicepresident/8.jpeg",
      title: "Collaborative Efforts: Refugee Aid Initiatives",
      date: "2024",
    },
    {
      image: "/icciu_pics/5vicepresident/6.jpeg",
      title: "Ramadan Generosity: Aid for Kiryandongo",
      date: "2024",
    },
    {
      image: "/icciu_pics/5vicepresident/4.jpeg",
      title: "Empowering Refugees: Ramadan Donation Drive",
      date: "2024",
    },
  ];

  const o6 = [
    {
      image: "/icciu_pics/6oic/1.jpeg",
      title: "Maa shaa Allah: Inspiring Moments at OIC",
      date: "2024",
    },
    {
      image: "/icciu_pics/6oic/6.jpeg",
      title: "OIC Gathering: Celebrating Achievements, Maa shaa Allah",
      date: "2024",
    },
    {
      image: "/icciu_pics/6oic/9.jpeg",
      title: "At OIC Maa shaa Allah",
      date: "2024",
    },
    {
      image: "/icciu_pics/6oic/3.jpeg",
      title: "Unity and Progress at OIC, Maa shaa Allah",
      date: "2024",
    },
    {
      image: "/icciu_pics/6oic/4.jpeg",
      title: "Maa shaa Allah: OIC's Dedication Shines Through",
      date: "2024",
    },
    {
      image: "/icciu_pics/6oic/5.jpeg",
      title: "OIC Highlights: Maa shaa Allah, A Time to Remember",
      date: "2024",
    },
  ];

  const s8 = [
    {
      image: "/icciu_pics/7saudi/18.jpeg",
      title:
        "ICCIU Secures Positive Support from Oman Foreign Affairs Ministry",
      date: "2024",
    },
    {
      image: "/icciu_pics/7saudi/19.jpeg",
      title:
        "Oman Chamber of Commerce Shows Positive Outlook for ICCIU Collaboration",
      date: "2024",
    },
    {
      image: "/icciu_pics/7saudi/11.jpeg",
      title: "ICCIU's Productive Meeting with Oman Chamber of Commerce",
      date: "2024",
    },
    {
      image: "/icciu_pics/7saudi/17.jpeg",
      title:
        "Jumaa Prayers at Masjid Sultan Qaboos in Muscat: A Spiritual Highlight for ICCIU Delegation",
      date: "2024",
    },
    {
      image: "/icciu_pics/7saudi/2.jpeg",
      title:
        "Positive News from Oman Foreign Affairs: ICCIU's Diplomatic Efforts Yield Results",
      date: "2024",
    },
    {
      image: "/icciu_pics/7saudi/1.jpeg",
      title: "Positive Developments: ICCIU's Search for Partners in Oman",
      date: "2024",
    },
    {
      image: "/icciu_pics/7saudi/4.jpeg",
      title:
        "Exploring New Opportunities: ICCIU's Visit to Oman's Chamber of Commerce and Industry",
      date: "2024",
    },
    {
      image: "/icciu_pics/7saudi/8.jpeg",
      title: "Building Bridges: ICCIU's Visit to Oman Chamber of Commerce",
      date: "2024",
    },
  ];

  const u12 = [
    {
      image: "/icciu_pics/8uae/17.jpeg",
      title:
        "Food Security Boost: UAE Donates Tons of Supplies to Sudanese Refugees in Kiryandongo",
      date: "2024",
    },
    {
      image: "/icciu_pics/8uae/20.jpeg",
      title:
        "UAE Steps Up with Tons of Food Assistance for Sudanese Refugees in Kiryandongo",
      date: "2024",
    },
    {
      image: "/icciu_pics/8uae/15.jpeg",
      title:
        "UAE's Humanitarian Effort: Tons of Food Aid for Sudanese Refugees in Kiryandongo",
      date: "2024",
    },
    {
      image: "/icciu_pics/8uae/16.jpeg",
      title:
        "Sudanese Refugees in Kiryandongo Benefit from UAE's Aid Initiative",
      date: "2024",
    },
    {
      image: "/icciu_pics/8uae/13.jpeg",
      title:
        "UAE Delivers Essential Food Supplies to Kiryandongo Refugee Settlement",
      date: "2024",
    },
    {
      image: "/icciu_pics/8uae/14.jpeg",
      title:
        "Massive UAE Food Donation Supports Sudanese Refugees in Kiryandongo",
      date: "2024",
    },
    {
      image: "/icciu_pics/8uae/10.jpeg",
      title:
        "UAE's Generous Donation: Tons of Food for Sudanese Refugees in Kiryandongo",
      date: "2024",
    },
    {
      image: "/icciu_pics/8uae/11.jpeg",
      title:
        "Sudanese Refugees in Kiryandongo Thank UAE for Significant Food Aid",
      date: "2024",
    },
    {
      image: "/icciu_pics/8uae/8.jpeg",
      title: "Kiryandongo Refugees Receive Substantial Food Support from UAE",
      date: "2024",
    },
    {
      image: "/icciu_pics/8uae/9.jpeg",
      title: "UAE Provides Vital Food Aid to Sudanese Refugees in Kiryandongo",
      date: "2024",
    },
    {
      image: "/icciu_pics/8uae/5.jpeg",
      title: "UAE Donates Tons of Food Aid to Sudanese Refugees in Kiryandongo",
      date: "2024",
    },
    {
      image: "/icciu_pics/8uae/6.jpeg",
      title:
        "Generous UAE Food Assistance Reaches Sudanese Refugees in Kiryandongo",
      date: "2024",
    },
  ];

  var articles = [
    f4[(article - 1) % 4],
    r6[(article - 1) % 6],
    v8[(article - 1) % 8],
    o6[(article - 1) % 6],
    s8[(article - 1) % 8],
    u12[(article - 1) % 12],
    m2[(article - 1) % 2],
    t1[(article - 1) % 1],
  ];
  return (
    <div className="surface-0 flex justify-content-center">
      <div id="home" className="landing-wrapper overflow-hidden bodylevel">
        <NavBar currentPath={currentLink} />
        <div id="slide">
          <ImageWithCaption
            imageUrl={main[article - 1]}
            caption={caption}
            blackBackground={blackBackground}
          />
        </div>
        <div id="about" className="py-0 px-4 lg:px-8 mt-0 mx-0 lg:mx-8">
          <div className="grid justify-content-center">
            <div className="col-12 lg:col-8">
              <div className="grid">
                <div className="col-12 lg:col-11">
                  <div className="master-card">{children}</div>
                  <RelatedArticles articles={articles} />
                  <div className="lg:col-1 col-0"></div>
                </div>
                {/* <div className="col-12 lg:col-12"></div> */}
              </div>
            </div>
            <div className="lg:col-4 col-0">
              <StackedLinks links={links} currentPath={currentLink} />
              <Updates />
            </div>
          </div>
        </div>
        <MarqueeSlider />
        <Footer />
      </div>
    </div>
  );
};

export default MasterPage;
