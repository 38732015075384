import React from "react";
import "./sections.css";

const Program = (props) => {
  const backgroundImageUrl = "url(" + `/icciu_pics/manpic.png`;
  return (
    <div
      data-aos="fade-up-right"
      data-aos-delay="100"
      data-aos-duration="1000"
      className="mt-2 mb-6 "
      style={{
        borderRadius: "20px",
        // backgroundImage: backgroundImageUrl,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        width: "100%",
        height: "fit-content",
        // background:
        //   "linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #EFE1AF 0%, #C3DCFA 100%)",
      }}
    >
      <div
        style={{
          maxWidth: "800px",
          // backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "black",
          // fontFamily: "Gabriola",
          fontSize: "17px",
          fontStyle: "italic",
          padding: "5px",
          borderRadius: "20px",
        }}
        className="flex flex-column justify-content-center align-items-center text-center py-3 mt-5 md:py-0"
      >
        <h3
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
          id="mandate"
          className="mb-2"
          style={{
            // backgroundColor: "black",
            // color: "white",
            padding: "5px",
          }}
        >
          {props.title}
        </h3>
        {props.paragraphs.map((paragraph, index) => (
          <p
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
            className="sm:line-height-2 md:line-height-4 text-1x2 mt-4"
            style={{
              maxWidth: "800px",
              // backgroundColor: "black",
              // opacity: "0.8",
              // color: "white",
              padding: "5px",
            }}
          >
            {paragraph}
          </p>
        ))}
        {/* <img
          data-aos="fade"
          data-aos-delay="100"
          data-aos-duration="1000"
          src="/demo/images/landing/ICCIU-2.png"
          className="mt-4"
          alt="Company logo"
          style={{
            maxWidth: "800px",
            backgroundColor: "white",
            // opacity: "0.5",
            color: "white",
            padding: "5px",
            marginBottom: "15px",
          }}
        /> */}
        {/* <img src="/demo/images/landing/peak-logo.svg" className="mt-4" alt="Company logo" /> */}
      </div>
    </div>
  );
};

export default Program;
