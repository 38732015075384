import React from "react";
import "./sections.css";

const Mandate = () => {
  const backgroundImageUrl = "url(" + `/icciu_pics/manpic.png`;
  return (
    <div
      data-aos="fade-up-right"
      data-aos-delay="100"
      data-aos-duration="1000"
      className="mt-2 mb-6 "
      style={{
        borderRadius: "20px",
        // backgroundImage: backgroundImageUrl,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        width: "100%",
        height: "fit-content",
        // background:
        //   "linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #EFE1AF 0%, #C3DCFA 100%)",
      }}
    >
      <div
        style={{
          maxWidth: "800px",
          // backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "black",
          fontFamily: "Gabriola",
          fontSize: "25px",
          fontStyle: "italic",
          padding: "5px",
          borderRadius: "20px",
        }}
        className="flex flex-column justify-content-center align-items-center text-center py-3 mt-5 md:py-0"
      >
        <h3
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
          id="mandate"
          className="mb-2"
          style={{
            // backgroundColor: "black",
            // color: "white",
            padding: "5px",
          }}
        >
          MANDATE
        </h3>
        <span
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
          className="text-2xl"
          style={{
            // backgroundColor: "black",
            // color: "white",
            padding: "5px",
          }}
        >
          ICCIU
        </span>
        <p
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
          className="sm:line-height-2 md:line-height-4 text-1x2 mt-4"
          style={{
            maxWidth: "800px",
            // backgroundColor: "black",
            // opacity: "0.8",
            // color: "white",
            padding: "5px",
          }}
        >
          The Islamic Chamber of Commerce and Industry Uganda (ICCIU) stands as
          a distinguished institution established in 2010 within the vibrant
          landscape of Uganda.
        </p>
        <p
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
          className="sm:line-height-2 md:line-height-4 text-1x2 mt-4"
          style={{
            maxWidth: "800px",
            // backgroundColor: "black",
            // opacity: "0.8",
            // color: "white",
            padding: "5px",
          }}
        >
          ICCIU works to promote a vibrant Private Sector and foster
          collaborations with local, regional and global chambers of commerce,
          governments, international organizations, educational and religious
          institutions in business, policy advocacy, information technology,
          capacity building, Research &amp; Innovations and agriculture.
        </p>
        <p
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
          className="sm:line-height-2 md:line-height-4 text-1x2 mt-4"
          style={{
            maxWidth: "800px",
            // backgroundColor: "black",
            // opacity: "0.8",
            // color: "white",
            padding: "5px",
          }}
        >
          Currently the Chamber is implementing a number of business program
          models under ICCIU Principles and her set goals through Public Private
          Partnerships (PPPs) for economic transformation.
        </p>
        <p
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
          className="sm:line-height-2 md:line-height-4 text-1x2 mt-4"
          style={{
            maxWidth: "800px",
            // backgroundColor: "black",
            // opacity: "0.8",
            // color: "white",
            padding: "5px",
          }}
        >
          Since 2010, The Chamber has been at the forefront of driving private
          sector business negotiations at National, Regional and Global Trade
          levels to meet Uganda’s vision 2040 “Middle income country”.
        </p>
        <p
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
          className="sm:line-height-2 md:line-height-4 text-1x2 mt-4"
          style={{
            maxWidth: "800px",
            // backgroundColor: "black",
            // opacity: "0.8",
            // color: "white",
            padding: "5px",
          }}
        >
          ICCIU has represented Uganda’s private sector in the EAC and
          tripartite deliberations and is committed to building sustainable
          business prosperity among private and public sector players.
        </p>
        {/* <img
          data-aos="fade"
          data-aos-delay="100"
          data-aos-duration="1000"
          src="/demo/images/landing/ICCIU-2.png"
          className="mt-4"
          alt="Company logo"
          style={{
            maxWidth: "800px",
            backgroundColor: "white",
            // opacity: "0.5",
            color: "white",
            padding: "5px",
            marginBottom: "15px",
          }}
        /> */}
        {/* <img src="/demo/images/landing/peak-logo.svg" className="mt-4" alt="Company logo" /> */}
      </div>
    </div>
  );
};

export default Mandate;
