import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./RelatedArticles.css"; // Create this CSS file for custom styling
import styled from "styled-components";

const Button = styled.button`
  border: 0;
  position: absolute;
  top: 40%;
  right: ${({ right }) => (right ? "-40px" : "auto")};
  left: ${({ right }) => (!right ? "-40px" : "auto")};
  z-index: 99;
  width: 60px; /* Adjust width as needed */
  height: 60px; /* Adjust height as needed */
  padding: 10px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);

  &:focus,
  &:active,
  &:hover {
    outline: none; /* Remove the default focus outline */
`;

const Arrow = ({ className, style, onClick }) => (
  <Button style={{ ...style }} onClick={onClick} className={className}>
    <div>back</div>
  </Button>
);

const Arrow2 = ({ className, style, onClick }) => (
  <Button style={{ ...style }} onClick={onClick} className={className} right>
    <div>next</div>
  </Button>
);

const RelatedArticles = ({ articles }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: articles.length > 0 ? <Arrow /> : null,
    nextArrow: articles.length > 0 ? <Arrow2 /> : null,
    arrows: articles.length > 0 ? true : false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="related-articles">
      <Slider {...settings}>
        {articles.map((article, index) => (
          <div key={index} className="article">
            <img
              src={article.image}
              alt={article.title}
              style={{ width: "100%" }}
            />
            <div className="article-info">
              <h3>{article.title}</h3>
              <p>{article.date}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default RelatedArticles;
