import React from "react";
import "./sections.css";
import ImageIcon from "../components/imageIcon";

const StrategicStatements = (props) => {
  return (
    <div>
      <div
        data-aos="fade-down-left"
        data-aos-delay="100"
        data-aos-duration="4000"
        className="col-12 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-10"
      >
        <h2 className="text-900 font-normal mb-2">{props.title}</h2>
        {/* <span className="text-600 text-2xl">Placerat in egestas erat...</span> */}
      </div>
      <div
        data-aos="flip-left"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="col-12 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0"
      >
        <div
          style={{
            height: "fit-content",
            padding: "2px",
            borderRadius: "10px",
            backgroundColor: "black",
            // "linear-gradient(90deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2))",
          }}
        >
          <div
            className="p-3 surface-card h-full"
            style={{ borderRadius: "8px" }}
          >
            {/* <div
                                        className="flex align-items-center justify-content-center bg-yellow-200 mb-3"
                                        style={{
                                            width: '3.5rem',
                                            height: '3.5rem',
                                            borderRadius: '10px'
                                        }}
                                    >
                                        <i className="pi pi-fw pi-users text-2xl text-yellow-700"></i>
                                    </div> */}
            <h5
              className="mb-2 text-green-600"
              style={{ display: "flex", alignItems: "center" }}
            >
              <ImageIcon imageUrl="/icciu_pics/vision.png" altText="Icon" />
              VISION
            </h5>
            <span className="text-600">
              A sustainably transformed private sector championing global trade.
            </span>
          </div>
        </div>
      </div>

      <div
        data-aos="flip-left"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="col-12 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0"
      >
        <div
          style={{
            height: "fit-content",
            padding: "2px",
            borderRadius: "10px",
            backgroundColor: "black",
            // "linear-gradient(90deg, rgba(145,226,237,0.2),rgba(251, 199, 145, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(172, 180, 223, 0.2))",
          }}
        >
          <div
            className="p-3 surface-card h-full"
            style={{ borderRadius: "8px" }}
          >
            {/* <div
                                        className="flex align-items-center justify-content-center bg-cyan-200 mb-3"
                                        style={{
                                            width: '3.5rem',
                                            height: '3.5rem',
                                            borderRadius: '10px'
                                        }}
                                    >
                                        <i className="pi pi-fw pi-palette text-2xl text-cyan-700"></i>
                                    </div> */}
            {/* <ImageIcon
              imageUrl="/icciu_pics/mission.png"
              altText="mission Icon"
            /> */}

            <h5
              className="mb-2 text-green-600"
              style={{ display: "flex", alignItems: "center" }}
            >
              <ImageIcon imageUrl="/icciu_pics/mission.png" altText="Icon" />
              MISSION
            </h5>
            <span className="text-600">
              To promote trade value chains at national and international repute
              through fostering conducive policy frameworks that enable members
              to achieve sustainable economic transformation.
            </span>
          </div>
        </div>
      </div>

      <div
        data-aos="flip-left"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="col-12 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0"
      >
        <div
          style={{
            height: "fit-content",
            padding: "2px",
            borderRadius: "10px",
            backgroundColor: "black",
            // "linear-gradient(90deg, rgba(145, 226, 237, 0.2), rgba(172, 180, 223, 0.2)), linear-gradient(180deg, rgba(172, 180, 223, 0.2), rgba(246, 158, 188, 0.2))",
          }}
        >
          <div
            className="p-3 surface-card h-full"
            style={{ borderRadius: "8px" }}
          >
            {/* <div
                                        className="flex align-items-center justify-content-center bg-indigo-200"
                                        style={{
                                            width: '3.5rem',
                                            height: '3.5rem',
                                            borderRadius: '10px'
                                        }}
                                    >
                                        <i className="pi pi-fw pi-map text-2xl text-indigo-700"></i>
                                    </div> */}
            <h5
              className="mb-2 text-green-600"
              style={{ display: "flex", alignItems: "center" }}
            >
              <ImageIcon imageUrl="/icciu_pics/values.png" altText="Icon" />
              CORE VALUES
            </h5>
            <span className="text-600">
              <ol>
                <li>Policy and advocacy</li>
                <li>Capacity building and Research</li>
                <li>Networking</li>
                <li>Value chain growth</li>
                <li>Ethics and Business Sustainability</li>
                <li>Accountability</li>
              </ol>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StrategicStatements;
