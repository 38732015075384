import React from "react";
import "../App.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primeicons/primeicons.css";
import "/node_modules/primeflex/primeflex.css";
import NavBar from "../components/navBar";
import Footer from "../sections/footer";
import Gallery from "../sections/gallery";
import MarqueeSlider from "../sections/Partnerships";

const GalleryPage = ({ currentLink = "/gallery" }) => {
  return (
    <div className="surface-0 flex justify-content-center">
      <div id="home" className="landing-wrapper overflow-hidden bodylevel">
        <NavBar currentPath={currentLink} />
        <div id="about" className="py-0 px-4 lg:px-4 mt-0 mx-0 lg:mx-4">
          <div className="grid justify-content-center">
            <div className="col-12 lg:col-12">
              <div className="grid">
                <div className="col-12 lg:col-12">
                  {/* <div className="master-card"> */}
                  <Gallery />
                  {/* </div> */}
                  <div className="lg:col-2 col-0"></div>
                </div>
                {/* <div className="col-12 lg:col-12"></div> */}
              </div>
            </div>
          </div>
        </div>
        <MarqueeSlider />
        <Footer />
      </div>
    </div>
  );
};

export default GalleryPage;
