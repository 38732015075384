import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as React from "react";
import { PrimeReactProvider } from "primereact/api";
import RegisterPage from "./pages/join";
import Statements from "./pages/StrategicStatementsPage";
import MandatePage from "./pages/MandatePage";
import Objectives from "./pages/StrategicObjectivesPage";
import Activities from "./pages/KeyActivitiesPage";
import Membership from "./pages/MembershipCategoryPage";
import Zakat from "./pages/zakatPage";
import Ramadhan2024 from "./pages/ramadhan2024Page";
import Trade from "./pages/trade";
import Publications from "./pages/publicationsPage";
import Contact from "./pages/contactUsPage";
import GalleryPage from "./pages/galleryPage";

const router = createBrowserRouter([
  // {
  //   path: "/react",
  //   element: <ReactPage></ReactPage>,
  // },
  // {
  //   path: "/button",
  //   element: <IndividualIntervalsExample></IndividualIntervalsExample>,
  // },
  {
    path: "/",
    element: <Statements></Statements>,
    // element: <RelatedArticles></RelatedArticles>,
  },
  {
    path: "/about",
    element: <Statements></Statements>,
  },
  {
    path: "/about/statements",
    element: <Statements></Statements>,
  },
  {
    path: "/about/mandate",
    element: <MandatePage></MandatePage>,
  },
  {
    path: "/about/objectives",
    element: <Objectives></Objectives>,
  },
  {
    path: "/about/activities",
    element: <Activities></Activities>,
  },
  {
    path: "/programs",
    element: <Ramadhan2024></Ramadhan2024>,
  },
  {
    path: "/programs/ramadhan2024",
    element: <Ramadhan2024></Ramadhan2024>,
  },
  {
    path: "/programs/membership",
    element: <Membership></Membership>,
  },
  {
    path: "/programs/zakat",
    element: <Zakat></Zakat>,
  },
  {
    path: "/programs/trade",
    element: <Trade></Trade>,
  },
  {
    path: "/programs/publications",
    element: <Publications></Publications>,
  },
  {
    path: "/gallery",
    element: <GalleryPage></GalleryPage>,
  },
  {
    path: "/contact",
    element: <Contact></Contact>,
  },
  {
    path: "/join",
    element: <RegisterPage></RegisterPage>,
  },
]);

function App() {
  return (
    <React.StrictMode>
      <PrimeReactProvider>
        <RouterProvider router={router} />
      </PrimeReactProvider>
    </React.StrictMode>
  );
}

export default App;
