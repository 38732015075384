import React from "react";
import { Card } from "primereact/card";
import Marquee, { Motion, randomIntFromInterval } from "react-marquee-slider";

const listOfLists = [
  ["Chambers of commerce", "/icciu_pics/icc.png"],
  ["Civil Society Organizations", "/icciu_pics/cso.png"],
  ["Media", "/icciu_pics/media.png"],
  ["Public Sector", "/icciu_pics/ps.png"],
  ["Development Partners", "/icciu_pics/dp.png"],
];

const MarqueeSlider = () => {
  return (
    <div
      className=" mt-8"
      style={{
        height: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundImage: "url(" + `/icciu_pics/partnership.jpeg`,
        // backgroundColor: "orange",
      }}
    >
      <div
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          paddingTop: "55px",
          paddingBottom: "55px",
        }}
      >
        {/* <h1 style={{ color: "white", textAlign: "center" }}>Partnerships</h1> */}
        <Marquee
          velocity={10}
          minScale={0.7}
          resetAfterTries={200}
          // scatterRandomly={true}
        >
          {listOfLists.map((sublist, index) => (
            // <Motion
            //   key={`child-${index}`}
            //   direction={"clockwise"}
            //   velocity={1}
            //   radius={0}
            // >
            <Card
              className="mx-5 text-center text-blue-900"
              style={{ height: "290px", width: "400px" }}
            >
              <img
                src={sublist[1]}
                alt=""
                height={"170"}
                // width={"170"}
                // style={{ borderRadius: "50%" }}
              />
              <h4>{sublist[0]}</h4>
            </Card>
            // </Motion>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default MarqueeSlider;
