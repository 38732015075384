import "../App.css";
import React from "react";
// import Link from "react-router-dom";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primeicons/primeicons.css";
import "/node_modules/primeflex/primeflex.css";
import Mandate from "../sections/mandate";
import MasterPage from "../masterPage";

function MandatePage() {
  return (
    <MasterPage
      imageUrl="/icciu_pics/slide4.jpeg"
      caption="The Islamic Chamber of Commerce and Industry Uganda - ICCIU"
      blackBackground={true}
      currentLink="/about/mandate"
      article={2}
    >
      <Mandate />
    </MasterPage>
  );
}

export default MandatePage;
