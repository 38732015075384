import logo from "../logo.svg";
import "../App.css";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
// import Link from "react-router-dom";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primeicons/primeicons.css";
import "/node_modules/primeflex/primeflex.css";
import { StyleClass } from "primereact/styleclass";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { Divider } from "primereact/divider";
// import { LayoutContext } from "../../layout/context/layoutcontext";
// import { NodeRef } from "@/types";
import { classNames } from "primereact/utils";
import MainSlide from "../components/carousel";
import ImageWithCaption from "../components/ImageDiv";
import Updates from "../sections/updates";

function NavBar(props) {
  const [isHidden, setIsHidden] = useState(false);
  //   const { layoutConfig } = useContext(LayoutContext);
  const menuRef = useRef(null);

  const toggleMenuItemClick = () => {
    setIsHidden((prevState) => !prevState);
  };

  const [isHovered, setIsHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    // Delay hiding the navbar for a smooth sliding effect
    setTimeout(() => {
      setIsVisible(false);
    }, 500);
  };
  const navbarStyle = {
    transition: "transform 0.5s ease-in-out",
    transform: isVisible ? "translateY(0)" : "translateY(-100%)",
  };
  return (
    <div
      // onMouseEnter={handleMouseEnter}
      // onTouchStart={handleMouseEnter}
      style={{
        position: "fixed",
        top: "0",
        zIndex: "100",
        width: "100vw",
      }}
    >
      <div
        // onMouseLeave={handleMouseLeave}
        // onTouchMove={handleMouseLeave}
        on
        style={{
          ...navbarStyle,
          ...(isHovered && { transform: "translateY(0)" }),
        }}
        className="py-3 px-4 mx-0 lg:px-8 flex align-items-center justify-content-between bg-white"
      >
        <a href="/" className="flex align-items-center App-link">
          {/* <img src={`/layout/images/${layoutConfig.colorScheme === 'light' ? 'logo-dark' : 'logo-white'}.svg`} alt="Sakai Logo" height="50" className="mr-0 lg:mr-2" /> */}
          <img
            src={`/layout/images/logo-1.png`}
            alt="ICCIU Logo"
            height="50"
            className="mr-0 lg:mr-2"
          />
          <span className="text-900 font-medium text-2xl line-height-3 mr-8">
            ICCIU
          </span>
        </a>
        <StyleClass
          nodeRef={menuRef}
          selector="@next"
          enterClassName="hidden"
          leaveToClassName="hidden"
          hideOnOutsideClick
        >
          <i
            ref={menuRef}
            className="pi pi-bars text-4xl cursor-pointer block lg:hidden text-700"
          ></i>
        </StyleClass>
        <div
          className={classNames(
            "align-items-center surface-0 flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 px-6 lg:px-0 z-2",
            { hidden: isHidden }
          )}
          style={{ top: "100%" }}
        >
          <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row cursor-pointer">
            {/* <li>
              <a
                href="#home"
                onClick={toggleMenuItemClick}
                className="p-ripple flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3"
              >
                <span>Home</span>
                <Ripple />
              </a>
            </li> */}
            <li>
              <a
                href="/about"
                onClick={toggleMenuItemClick}
                className={`p-ripple flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 App-link ${
                  props.currentPath.startsWith("/about") ? "current-link" : ""
                }`}
              >
                <span>About Us</span>
                <Ripple />
              </a>
            </li>
            <li>
              <a
                href="/programs"
                onClick={toggleMenuItemClick}
                className={`p-ripple flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 App-link ${
                  props.currentPath.startsWith("/programs")
                    ? "current-link"
                    : ""
                }`}
              >
                <span>Programs</span>
                <Ripple />
              </a>
            </li>
            <li>
              <a
                href="/gallery"
                onClick={toggleMenuItemClick}
                className={`p-ripple flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 App-link ${
                  props.currentPath.startsWith("/gallery") ? "current-link" : ""
                }`}
              >
                <span>Gallery</span>
                <Ripple />
              </a>
            </li>
            <li>
              <a
                href="/contact"
                onClick={toggleMenuItemClick}
                className={`p-ripple flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 App-link ${
                  props.currentPath.startsWith("/contact") ? "current-link" : ""
                }`}
              >
                <span>Contact Us</span>
                <Ripple />
              </a>
            </li>
          </ul>
          <div className="flex justify-content-between lg:incline border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0">
            {/* <Button
                label="Login"
                text
                rounded
                className="border-none font-light line-height-2 text-blue-500"
                ></Button> */}
            <a href="/programs/zakat" className="lg:mr-3">
              <Button
                label="Donate"
                rounded
                className="border-none px-5 w-12 font-light line-height-2 bg-green-500 text-white"
              ></Button>
            </a>
            <a href="/join" className="lg:mr-0">
              <Button
                label="Join"
                rounded
                className="border-none px-5 w-12 font-light line-height-2 bg-blue-500 text-white"
              ></Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
