import "../App.css";
import React from "react";
// import Link from "react-router-dom";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primeicons/primeicons.css";
import "/node_modules/primeflex/primeflex.css";
import Program from "../sections/program";
import MasterPage from "../masterPage";

function Publications() {
  return (
    <MasterPage
      imageUrl="/icciu_pics/slide4.jpeg"
      caption="The Islamic Chamber of Commerce and Industry Uganda - ICCIU"
      blackBackground={true}
      currentLink="/programs/publications"
      article={8}
    >
      <Program
        title="Publications"
        paragraphs={[
          "Welcome to the Publications section of the Islamic Chamber of Commerce in Uganda (ICCIU). Our Publications division serves as a valuable resource hub, providing insightful content and research materials tailored to the needs of our community.",
          "At ICCIU Publications, we are committed to promoting knowledge sharing, fostering intellectual growth, and empowering individuals with information that enriches their understanding of Islamic finance, commerce, and entrepreneurship.",
          "Explore our wide range of publications, including articles, reports, whitepapers, and educational guides, covering various topics related to Islamic economics, business ethics, and sustainable development. Whether you're a business owner, entrepreneur, student, or simply curious about Islamic finance principles, you'll find valuable insights and practical guidance within our publications.",
          "Stay informed about the latest trends, developments, and best practices in the field of Islamic commerce through our regularly updated content. From in-depth analyses of market trends to practical advice on business management, our publications offer a wealth of knowledge to help you succeed in your endeavors.",
          "Engage with our community of thought leaders, scholars, and industry experts through our publications platform. Contribute your own insights, share your experiences, and participate in meaningful discussions that shape the future of Islamic commerce and entrepreneurship.",
          "Join us on a journey of learning, discovery, and growth as we strive to advance the principles of Islamic finance and commerce. Welcome to ICCIU Publications, where knowledge is power and insight leads to success.",
        ]}
      />
    </MasterPage>
  );
}

export default Publications;
