import React from "react";
import "./sections.css"; // Import CSS for styling

const StackedLinks = (props) => {
  return (
    <div className="centered-container">
      <div className="stacked-links-container">
        {/* <h2 className="stacked-links-header">Explore More</h2> */}
        <ul className="stacked-links-list">
          {props.links.map((link, index) => (
            <li
              key={index}
              data-aos="zoom-in"
              data-aos-delay="50"
              data-aos-duration="1000"
              className={`${
                props.currentPath === link[0] ? "current-link" : ""
              } ${
                link[0] === "/about" ||
                link[0] === "/programs" ||
                link[0] === "/contact"
                  ? "headerLink"
                  : ""
              }`}
            >
              <a href={link[0]}>
                <span>{link[1]}</span>
                <i className="fas fa-arrow-right"></i>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default StackedLinks;
