import React from "react";
import "./sections.css";

const Footer = () => {
  return (
    <div className="bg-black-alpha-90 py-4 px-4 mx-0 lg:mx-0 lg:px-8 lg:py-8">
      <div
        // data-aos="zoom-out"
        // data-aos-delay="100"
        // data-aos-duration="1000"
        className="grid justify-content-between"
      >
        <div className="col-12 mt-5 md:col-2" style={{ marginTop: "-1.5rem" }}>
          <a
            href="/"
            className="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3 cursor-pointer"
          >
            <img
              src={`/layout/images/logo-1.png`}
              alt="footer sections"
              width="50"
              height="50"
              className="mr-2"
            />
            {/* <img src={`/layout/images/${layoutConfig.colorScheme === 'light' ? 'logo-dark' : 'logo-white'}.svg`} alt="footer sections" width="50" height="50" className="mr-2" /> */}
            <span className="font-medium text-3xl text-100">ICCIU</span>
          </a>
        </div>

        <div className="col-12 md:col-10 lg:col-7">
          <div className="grid text-center md:text-left">
            <div className="col-12 md:col-4">
              <h4 className="font-medium text-2xl line-height-3 mb-3 text-100">
                About Us
              </h4>
              <a
                href="/about/statements"
                className="line-height-3 text-1x2 block cursor-pointer mb-2 text-400"
              >
                Strategic Statements
              </a>
              <a
                href="/about/mandate"
                className="line-height-3 text-1x2 block cursor-pointer mb-2 text-400"
              >
                Mandate
              </a>
              <a
                href="/about/objectives"
                className="line-height-3 text-1x2 block cursor-pointer mb-2 text-400"
              >
                Strategic Objectives
              </a>
              <a
                href="/about/activities"
                className="line-height-3 text-1x2 block cursor-pointer mb-2 text-400"
              >
                Key Activities
              </a>
            </div>

            <div className="col-12 md:col-4 mt-4 md:mt-0">
              <h4 className="font-medium text-1x2 line-height-3 mb-3 text-100">
                Programs
              </h4>
              <a
                className="line-height-3 text-1x2 block cursor-pointer mb-2 text-400"
                href="/programs/ramadhan2024"
              >
                Ramadhan 2024
              </a>
              <a
                className="line-height-3 text-1x2 block cursor-pointer mb-2 text-400"
                href="/programs/publications"
              >
                Publications
              </a>
              <a
                className="line-height-3 text-1x2 block cursor-pointer mb-2 text-400"
                href="/programs/zakat"
              >
                Zakat and Charity
              </a>
              <a
                className="line-height-3 text-1x2 block cursor-pointer mb-2 text-400"
                href="/programs/trade"
              >
                Trade and Commerce
              </a>
              <a
                className="line-height-3 text-1x2 block cursor-pointer mb-2 text-400"
                href="/programs/membership"
              >
                Membership
              </a>
            </div>

            <div className="col-12 md:col-4 mt-4 md:mt-0">
              <h4 className="font-medium text-1x2 line-height-3 mb-3 text-100">
                Community
              </h4>
              <a
                className="line-height-3 text-1x2 block cursor-pointer mb-2 text-400"
                href="https://www.facebook.com/groups/1554370087989100"
                target="_blank"
              >
                Facebook
              </a>
              {/* <a className="line-height-3 text-1x2 block cursor-pointer mb-2 text-400">
                LinkedIn
                <img
                  src="/demo/images/landing/new-badge.svg"
                  className="ml-2"
                  alt="badge"
                />
              </a> */}
              <a
                href="https://twitter.com/IslamicICCIU"
                target="_blank"
                className="line-height-3 text-1x2 block cursor-pointer mb-2 text-400"
              >
                Twitter(X)
              </a>
              <a
                href="https://www.tiktok.com/@islamicchamber.icciu"
                target="_blank"
                className="line-height-3 text-1x2 block cursor-pointer text-400"
              >
                Tiktok
              </a>
            </div>

            {/* <div className="col-12 md:col-3 mt-4 md:mt-0">
              <h4 className="font-medium text-1x2 line-height-3 mb-3 text-100">
                Legal
              </h4>
              <a className="line-height-3 text-1x2 block cursor-pointer mb-2 text-400">
                Brand Policy
              </a>
              <a className="line-height-3 text-1x2 block cursor-pointer mb-2 text-400">
                Privacy Policy
              </a>
              <a className="line-height-3 text-1x2 block cursor-pointer text-400">
                Terms of Service
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
