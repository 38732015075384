import "../App.css";
import React from "react";
// import Link from "react-router-dom";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primeicons/primeicons.css";
import "/node_modules/primeflex/primeflex.css";
import Program from "../sections/program";
import MasterPage from "../masterPage";

function Zakat() {
  return (
    <MasterPage
      imageUrl="/icciu_pics/slide4.jpeg"
      caption="The Islamic Chamber of Commerce and Industry Uganda - ICCIU"
      blackBackground={true}
      currentLink="/programs/zakat"
      article={9}
    >
      <Program
        title="Zakat and Charity"
        paragraphs={[
          "Welcome to the Zakat Payment Program by the Islamic Chamber of Commerce in Uganda (ICCIU). Our program simplifies the process of fulfilling your Zakat obligations, empowering Muslims to contribute to charitable causes with ease and convenience.",
          "At the Zakat Payment Program, we understand the importance of Zakat in Islam and the impact it can have on individuals and communities. Our goal is to make Zakat payments accessible to all, ensuring that those in need receive timely support and assistance.",
          "With our user-friendly platform, you can easily calculate and submit your Zakat payments online, allowing you to fulfill your religious duties with just a few clicks. Whether you're a seasoned donor or new to the concept of Zakat, our program provides the resources and support you need to make a meaningful impact.",
          "Join our community of compassionate individuals dedicated to making a difference through charitable giving. Together, we can strengthen our community, uplift those in need, and magnify the blessings of Sadaqah and Zakat.",
          "Experience the joy of giving back and supporting those less fortunate. Welcome to the Zakat Payment Program by ICCIU, where your contributions make a real difference in the lives of others.",
        ]}
      />
    </MasterPage>
  );
}

export default Zakat;
