import React from "react";

const ImageIcon = ({
  imageUrl,
  altText,
  height1 = "24px",
  width1 = "24px",
}) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={imageUrl}
        alt={altText}
        style={{ width: height1, height: width1, marginRight: "8px" }}
      />
    </div>
  );
};

export default ImageIcon;
