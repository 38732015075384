import React from "react";
import "./sections.css";

const Updates = () => {
  return (
    <div className="news-feed">
      <div
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="news-item"
      >
        <h2 className="news-title">Support for Expecting Refugee Mothers</h2>
        <p className="news-description">
          Help us support 20 expecting refugee mothers in Kiryandongo. A $100
          donation provides a delivery package with baby clothes, toiletries, a
          mother kit, and essentials. Additionally, we need items like beds,
          mattresses, pillows, sheets, newborn clothes, soap, pampers, baby oil,
          Dettol soap, cotton, and sanitary pads. Your contribution can make a
          vital difference.
        </p>
      </div>

      <div
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="news-item"
      >
        <h2 className="news-title">Additional Donations</h2>
        <p className="news-description">
          The Direct Aid Society donated to refugees in the Adjumani District,
          further aiding the community.
        </p>
      </div>

      <div
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="news-item"
      >
        <h2 className="news-title">Visits and Partnerships</h2>
        <p className="news-description">
          ICCIU visited Saudi Arabia to seek partnerships and opportunities for
          Uganda, resulting in positive engagement with the Oman Chamber and
          encouraging news from the PS Oman Foreign Affairs.
        </p>
      </div>
    </div>
  );
};

export default Updates;
