import "../App.css";
import React from "react";
// import Link from "react-router-dom";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primeicons/primeicons.css";
import "/node_modules/primeflex/primeflex.css";
import ContactUs from "../sections/contactUs";
import MasterPage from "../masterPage";

function Contact() {
  return (
    <MasterPage
      imageUrl="/icciu_pics/contact.jpeg"
      caption="The Islamic Chamber of Commerce and Industry Uganda - ICCIU"
      blackBackground={true}
      currentLink="/contact"
      article={10}
    >
      <ContactUs title="Strategic Statements" />
    </MasterPage>
  );
}

export default Contact;
