import "./gallery.css";
import React, { useState } from "react";
import Modal from "react-modal";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const images = [
  "/icciu_pics/1feasibilitystudy/2.jpeg",
  "/icciu_pics/1feasibilitystudy/3.jpeg",
  "/icciu_pics/1feasibilitystudy/4.jpeg",
  "/icciu_pics/1feasibilitystudy/5.jpeg",
  "/icciu_pics/2Turkish/1.jpeg",
  "/icciu_pics/3refugeeMothers/1.jpeg",
  "/icciu_pics/3refugeeMothers/2.jpeg",

  "/icciu_pics/4supportRefugee/1.jpeg",
  "/icciu_pics/4supportRefugee/9.jpeg",
  "/icciu_pics/4supportRefugee/8.jpeg",
  "/icciu_pics/4supportRefugee/4.jpeg",
  "/icciu_pics/4supportRefugee/5.jpeg",
  "/icciu_pics/4supportRefugee/6.jpeg",
  "/icciu_pics/5vicepresident/1.jpeg",

  "/icciu_pics/5vicepresident/3.jpeg",
  "/icciu_pics/5vicepresident/14.jpeg",
  "/icciu_pics/5vicepresident/13.jpeg",
  "/icciu_pics/5vicepresident/9.jpeg",
  "/icciu_pics/5vicepresident/8.jpeg",
  "/icciu_pics/5vicepresident/6.jpeg",
  "/icciu_pics/5vicepresident/4.jpeg",

  "/icciu_pics/6oic/1.jpeg",
  "/icciu_pics/6oic/6.jpeg",
  "/icciu_pics/6oic/9.jpeg",
  "/icciu_pics/6oic/3.jpeg",
  "/icciu_pics/6oic/4.jpeg",
  "/icciu_pics/6oic/5.jpeg",
  "/icciu_pics/7saudi/18.jpeg",

  "/icciu_pics/7saudi/19.jpeg",
  "/icciu_pics/7saudi/11.jpeg",
  "/icciu_pics/7saudi/17.jpeg",
  "/icciu_pics/7saudi/2.jpeg",
  "/icciu_pics/7saudi/1.jpeg",
  "/icciu_pics/7saudi/4.jpeg",
  "/icciu_pics/7saudi/8.jpeg",

  "/icciu_pics/8uae/17.jpeg",
  "/icciu_pics/8uae/20.jpeg",
  "/icciu_pics/8uae/15.jpeg",
  "/icciu_pics/8uae/16.jpeg",
  "/icciu_pics/8uae/13.jpeg",
  "/icciu_pics/8uae/14.jpeg",
  "/icciu_pics/8uae/10.jpeg",

  "/icciu_pics/8uae/11.jpeg",
  "/icciu_pics/8uae/8.jpeg",
  "/icciu_pics/8uae/9.jpeg",
  "/icciu_pics/8uae/5.jpeg",
  "/icciu_pics/8uae/6.jpeg",
];

Modal.setAppElement("#root");

function Gallery() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const numberOfLoops = Math.ceil(images.length / 7);
  const galleryItems = [];

  for (let loop = 0; loop < numberOfLoops; loop++) {
    for (let i = 0; i < Math.min(7, images.length); i++) {
      const index2 = i + loop * 7;
      const index = i;
      if (images[index2]) {
        galleryItems.push(
          <div
            key={index2}
            className={`gallery-item item${index + 1}`}
            onClick={() => openModal(index2)}
          >
            <img
              src={images[index2]}
              // title={index2}
              alt={`Item ${index2 + 1}`}
            />
          </div>
        );
      }
    }
  }

  return (
    <div className="App">
      <h1>Photo Gallery</h1>
      <div className="gallery">{galleryItems}</div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Carousel"
        className="Modal"
        overlayClassName="Overlay"
      >
        <Carousel
          selectedItem={currentImageIndex}
          onChange={(index) => setCurrentImageIndex(index)}
          showThumbs={false}
          showStatus={false}
          showIndicators={false} // Hide the bottom dots
          useKeyboardArrows
          swipeable
          dynamicHeight
          autoPlay
        >
          {images
            .filter((image) => image)
            .map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  alt={`Item ${index + 1}`}
                  className="responsive-image"
                />
              </div>
            ))}
        </Carousel>
      </Modal>
    </div>
  );
}

export default Gallery;
