import "../App.css";
import React from "react";
// import Link from "react-router-dom";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primeicons/primeicons.css";
import "/node_modules/primeflex/primeflex.css";
import Program from "../sections/program";
import MasterPage from "../masterPage";

function Ramadhan2024() {
  return (
    <MasterPage
      imageUrl="/icciu_pics/slide4.jpeg"
      caption="The Islamic Chamber of Commerce and Industry Uganda - ICCIU"
      blackBackground={true}
      currentLink="/programs/ramadhan2024"
      article={5}
    >
      <Program
        title="Ramadan Donations and Support Activities at Kiryandongo Refugee Settlement"
        paragraphs={[
          "During the month of Ramadan in 2024, several organizations provided crucial support to the Kiryandongo Refugee Settlement through generous donations. Over the course of four weeks, these contributions significantly benefited the refugees and the host community.",
          "In the first week, the Turkish Uganda Humanitarian Foundation opened the way by donating 3 cows, 8 goats, and 500 kg of rice for iftar meals. Their generosity was deeply appreciated.",
          "The second week saw contributions from One Ummah Uganda, which donated 1,800 food packages and 600 copies of the Quran, a gesture that was highly valued.",
          "The third week brought support from ICFER, which donated 6 cows for iftar and provided 75 desks to Ematongo Primary School, benefiting approximately 600 Sudanese refugee children. Additionally, they donated health and welfare materials, all of which were gratefully received.",
          "In the fourth week, the UAE Ambassador made a significant contribution by donating 15,000 food packages, with 12,000 going to Sudanese refugees and 3,000 to the host community. Managing the storage and distribution of this large quantity of food was a substantial effort throughout the week. Excess food was redistributed to both refugees and the host community for the Eid celebration. The Turkish Uganda Humanitarian Foundation also donated two cows for the Eid feast, concluding the Ramadan activities on a positive note.",
          "The Ramadan donations and activities have played a vital role in supporting the Kiryandongo Refugee Settlement, ensuring that both refugees and the host community receive essential supplies and assistance. Continued support, particularly for expecting mothers, remains a critical area of need.",
        ]}
      />
    </MasterPage>
  );
}

export default Ramadhan2024;
