import "../App.css";
import React from "react";
// import Link from "react-router-dom";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primeicons/primeicons.css";
import "/node_modules/primeflex/primeflex.css";
import Program from "../sections/program";
import MasterPage from "../masterPage";

function Trade() {
  return (
    <MasterPage
      imageUrl="/icciu_pics/slide4.jpeg"
      caption="The Islamic Chamber of Commerce and Industry Uganda - ICCIU"
      blackBackground={true}
      currentLink="/programs/trade"
      article={7}
    >
      <Program
        title="Trade and Commerce"
        paragraphs={[
          "Welcome to the Trade and Commerce division of the Islamic Chamber of Commerce in Uganda (ICCIU). Our division is dedicated to fostering economic growth, promoting trade relations, and empowering businesses within the Muslim community.",
          "At ICCIU Trade and Commerce, we recognize the vital role that trade plays in advancing prosperity and fostering development. Our mission is to facilitate and support trade initiatives, entrepreneurship, and investment opportunities that align with Islamic principles and values.",
          "Explore our platform to discover a wealth of resources and services tailored to the needs of businesses and entrepreneurs. Whether you're a seasoned trader, aspiring entrepreneur, or looking to expand your business network, we offer a range of tools and support to help you succeed.",
          "Engage with our community of business leaders, entrepreneurs, and industry experts through networking events, seminars, and workshops. Connect with like-minded individuals, share insights, and explore collaboration opportunities that drive innovation and growth.",
          "Stay informed about the latest market trends, regulatory updates, and business opportunities through our comprehensive research and analysis. From market reports to industry insights, our resources provide valuable information to help you make informed decisions and seize new opportunities.",
          "Join us in promoting ethical business practices, sustainability, and social responsibility within the Muslim business community. Together, we can build a vibrant and inclusive ecosystem that empowers businesses to thrive while upholding the principles of Islamic finance and commerce.",
          "Welcome to ICCIU Trade and Commerce, where opportunities abound, and success knows no bounds.",
        ]}
      />
    </MasterPage>
  );
}

export default Trade;
